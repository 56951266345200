



























import { defineComponent, ref, useContext, onMounted, useFetch, computed, useRouter } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'NewsLetterForm',
    components: {

    },
    setup() {
        const k_email = ref('');
        const newsletterform = async (url, k_email) => {
            if (k_email) {
                if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(k_email)){
                    window.location.href = url;
                } else {
                    alert('Please enter a valid email address (Ex: johndoe@domain.com).');
                }
            } else {
                alert('Please enter a valid email address (Ex: johndoe@domain.com).');
            }
        }
        return {
            k_email,
            newsletterform,
        };
    },
});
