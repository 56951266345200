export default `
  query footerConfig {
    footerConfig{
        even_more {
            title
            target
            type
            url
        }
        about_us {
            title
            target
            type
            url
        }
        find_us_content
        social_links {
            title
            target
            url
        }
        copy_right
    }
}
`;