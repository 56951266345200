
































































































































































































import { SfFooter, SfList, SfImage, SfMenuItem, SfLink } from '@storefront-ui/vue';
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { generateLink } from '~/helpers/addBasePath';
import { useFooter } from './useFooter';
import NewsLetterForm from './NewsLetterForm.vue';
import { numeric } from 'vee-validate/dist/rules';

export default defineComponent({
  name: 'FooterSection',
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    SfLink,
    NewsLetterForm,
  },
  setup() {
    const { footerContent } = useFooter();
    const toggelFind = ref(false);
    const toggelAbout = ref(false);
    const toggelEven = ref(false);
    
    const find_us_class = ref('');
    const find_us_classChanged = () => {
      find_us_class.value = 'active';
      return find_us_class;
    };

    const about_us_class = ref('');
    const about_us_classChanged = () => {
      about_us_class.value = 'active';
      return about_us_class;
    };

    const even_more_class = ref('');
    const even_more_classChanged = () => {
      even_more_class.value = 'active';
      return even_more_class;
    };

    const mp_customer_email = ref('');
    const __lcmj = ref<{ license: number }>({ license: 4319041 });

    onMounted( () => {
      mp_customer_email.value = localStorage.getItem('mp_customer_email');
      var _learnq = _learnq || [];
      _learnq.push(['identify', {
        // Change the line below to dynamically print the user's email.
        '$email' : mp_customer_email
      }]);

      /* var __lc = __lcmj.value;
      window['__lc'] = __lc;
      (function(){var lc=document.createElement('script');lc.type='text/javascript';lc.async=true;lc.src=('https:'==document.location.protocol?'https://':'http://')+'cdn.livechatinc.com/tracking.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(lc,s);})(); */
    });


    return {
      about_us_class,
      about_us_classChanged,
      even_more_class,
      even_more_classChanged,
      find_us_class,
      find_us_classChanged,
      generateLink,
      footerContent,
      toggelFind,
      toggelAbout,
      toggelEven
    };
  },
});
